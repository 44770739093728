<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        ဌာန အကောင့်
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row search-form">
                                    <div class="col-md-3 mt-1">
                                        <input type="text" v-model="filter.name" class="form-control" placeholder="နာမည်">
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input type="text" v-model="filter.email" class="form-control" placeholder="အီးမေးလ်">
                                    </div>
                                    <!-- <div class="col-md-3 mt-1">
                                        <input type="text" v-model="filter.department" class="form-control" placeholder="department">
                                    </div> -->
                                    <div class="col-md-3 mt-1">
                                        <button class="btn btn-primary mr-1 fbutton" @click="search()"><i class="fas fa-search"></i> လုပ်ဆောင်မည်</button>
                                        <button class="btn btn-info fbutton" @click="reset()"><i class="fas fa-sync"></i> အသစ်ပြန်စမည်</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th class="th-width-100">ဌာန</th>
                                                <th class="th-width-100">ရာထူး</th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td class="pt-4">{{data.name}}</td>
                                                <td class="pt-4">{{data.email}}</td>
                                                <td class="pt-4">{{data.department}}</td>
                                                <td class="pt-4">{{data.position}}</td>
                                                <td class="pt-2">
                                                    <button class="btn btn-option text-primary"
                                                        @click="editData(data)"><i class="fas fa-edit"></i></button>
                                                    <!-- <button v-b-modal.bv-modal-delete
                                                        class="btn btn-option text-danger"><i
                                                            class="fas fa-trash-alt" @click="deleteData(data)"></i></button> -->
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="5" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">Delete</b-button>
        </b-modal>
        <router-link class="float" to="department-account-create">
            <i class="fa fa-plus my-float" style="padding: 37%;"></i>
        </router-link>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'dAccountList',
        metaInfo: {
            title: "Department Account List",
            titleTemplate: "%s ← SC Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                dataList: [],
                columns: {
                    name: "နာမည်",
                    email: "အီးမေးလ်",
                },
                filter: {
                    page: 1,
                    reverse: "asc",
                    sort: "created_at",
                    name: "",
                    email: "",
                    department_id: "",
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null
                }
            }
        },
        watch: {
        '$route' (to, from) {
            this.fetchData()
        }
    },
        computed: {},
        methods: {
            ...mapActions({
                dAccountListAction: 'dAccountListAction',
                dAccountDeleteAction: 'dAccountDeleteAction',
            }),
            sortBy(column) {
                this.filter.sort = column
                if (this.filter.sort == column) {
                    if (this.filter.reverse == "asc") {
                        this.filter.reverse = "desc"
                    } else if (this.filter.reverse == "desc") {
                        this.filter.reverse = "asc"
                    }
                } else {
                    this.filter.reverse = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
            },
            async fetchData() {
                this.isLoading = true
                let option = this.filter
                await this.dAccountListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.$router.replace({ path: 'department-account-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, name: this.filter.name, email: this.filter.email, department_id: this.filter.department_id} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            reset(){
                this.$router.replace({ path: 'department-account-list', query: {page: 1, reverse: 'desc', sort: 'created_at', name: '', email: '', department_id: ''} }).catch(()=>{})
                 this.$router.go()
            },
            editData(data) {
                this.$router.replace({ path: 'department-account-edit', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, id: data.id, name: this.filter.name, email: this.filter.email, department_id: this.filter.department_id } }).catch(()=>{})
            },
            deleteData(data){
                this.id = data.id
            },
            async deleteItem() {
				this.isLoading = true
				let option = {
					id: this.id,
				}
				await this.dAccountDeleteAction({
					...option
				}).then(res => {
					this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},

        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.name = this.$route.query.name
            this.filter.email = this.$route.query.email
            this.filter.department_id = this.$route.query.department_id
            this.fetchData()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>